



































































































import Vue from 'vue';
import {Business} from "@/interfaces/business/business";
import { BusinessMergerRepository } from '@/repositories/businessMergerRepository';
import BusinessMergerDetailsComponent from "@/components/business/merger/BusinessMergerDetailsComponent.vue"
import { BusinessMergerReceipt } from '@/interfaces/business/mergerReceipt';

export default Vue.extend({
  components: {BusinessMergerDetailsComponent},
  props: {
    selectedBusiness: Object as () => Business
  },
  data() {
    return {
      loading: false,
      error: false,
      activeId: -1,
      history: [] as BusinessMergerReceipt[],
      mergeRepository: new BusinessMergerRepository(this),
      selectedHistory: null as BusinessMergerReceipt | null,
    }
  },
  watch: {
    selectedBusiness(newBusiness: Business) {
      if (newBusiness) {
          this.getHistory(newBusiness.id)
          this.selectedHistory = null;
      }
    }
  },
  created() {
    if (this.selectedBusiness) {
        this.getHistory(this.selectedBusiness.id)
    }
  },
  methods: {
    getIcon(receipt: BusinessMergerReceipt): string {
      if (receipt.reverted) {
        return "mdi-undo-variant"
      } else if (receipt.expired) {
        return "mdi-timer-off-outline"
      }
      return "mdi-timer-outline";
    },
    getColor(receipt: BusinessMergerReceipt): string {
      if (receipt.reverted || receipt.expired) {
        return "error"
      } 
      return "success"
    },
    selectHistory(history: BusinessMergerReceipt) {
      this.selectedHistory = history;
      this.activeId = history.id;
    },
    async getHistory(businessId: number) {
      try {
        this.error = false
        this.loading = true
        const data = await this.mergeRepository.getBusinessMergers(businessId)
        this.history = data
      }
      catch (e) {
        console.error(`failed to fetch history: ${e}`)
        this.error = true
      }
      finally {
        this.loading = false
      }
    },
    onReverted() {
      this.selectedHistory = null
      this.getHistory(this.selectedBusiness.id)
    }
  }
})
