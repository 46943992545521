import { ENDPOINTS, get, put } from "@/api/mainApi";
import { BusinessMergerReceipt } from "@/interfaces/business/mergerReceipt";
import { Snackbar } from "@/interfaces/presentation/snackbar";

export class BusinessMergerRepository {
    constructor(private vueInstance: Vue) {}

    public async getBusinessMergers(businessId: number, active?: boolean): Promise<BusinessMergerReceipt> {
        let searchParams = new URLSearchParams()
        if (active) {
            searchParams.append("active",  active.toString());
        }

        const url = `${ENDPOINTS.BUSINESSES}/${businessId}/${ENDPOINTS.MERGER_RECEIPTS}${searchParams.values.length != 0 ? '?' : '' }` + searchParams.toString();
        return await get(url)
    }

    public async undoMerger(mergerId: number): Promise<any> {
        const url = `${ENDPOINTS.BUSINESSES}/${ENDPOINTS.UNDO}/${ENDPOINTS.MOVE}/${mergerId}`
        try {
            let res = await put(url, {})
            this.setSnackbar("Flytt ångrad", 'success')
            return res;
        } catch (error) {
            if (this.isErrorWithData(error)) {
                this.handleError(error, `Misslyckades att ångra flytt: ${error.data.msg}`)
            } else {
                this.handleError(error, `Misslycakdes att ångra flytt: Okänt fel`)
            }
            return Promise.reject();
        }
    }

    private setSnackbar(message: string, color: 'error' | 'success') {
        const snackbar: Snackbar = {
            active: true,
            color: color,
            text: message,
            timeout: 10000,
            action: null,
        };
        this.vueInstance.$store.commit('setSnackbar', snackbar);
    }

    private handleError(error: any, errorMessage: string) {
        if (error.response?.status === 403) {
            this.setSnackbar('Du saknar rättigheter för att utföra den begärda handlingen', 'error');
        } else {
            this.setSnackbar(errorMessage, 'error');
        }
    }

    private isErrorWithData(error: unknown): error is { data: { msg: string } } {
        return typeof error === 'object' && error !== null && 'data' in error && typeof (error as any).data.msg === 'string';
    }
}